import "./App.css";
import Navbar from "./components/navbar-component/navbar-component";
import Header from "./components/header-component/header-component";
import Footer from "./components/footer-component/footer-component";

function App() {
  return (
    <div className="App">
      <Header />
      <Navbar />
      <Footer />
    </div>
  );
}

export default App;
