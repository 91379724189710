import React from "react";

import "./footer.css";

const Footer = () => {
  return <div>


    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">Happy Coders teaches kids programming both online and in-person in a creative, happy and playful way. We want to convert the screen time of today’s kids into a happy learning time that they would enjoy as much as they enjoy playing video games or watching YouTube. Kids will build their own games, animations, apps or websites and by doing so they will learn the valuable programming and problem-solving skills and will build the confidence to thrive in the digital world of the 21st century.  </p>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Categories</h6>
            <ul class="footer-links">
              <li><a href="/programs">Scratch_01</a></li>
              <li><a href="/programs">Scratch_02</a></li>
              <li><a href="/programs">Scratch_03</a></li>
              <li><a href="/programs">Python_01</a></li>
              <li><a href="/programs">Python_02</a></li>
              <li><a href="/programs">Python_03</a></li>
            </ul>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul class="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/programs">Programs</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/faq">FAQ</a></li>
              
              <li><a href="/trial">Trial Class</a></li>
            </ul>
          </div>
        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
         <a href="#">Happycoders</a>.
            </p>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12">
            <ul style={{  display: "flex",
  justifycontent: "space-between",
  alignitems: "center"}} class="social-icons">
               <p  className="paragraph">follow us on </p>
               <p>  </p>
              <li ><a class="facebook" href="https://www.facebook.com/happycodersbd"><i class="fa fa-facebook"></i></a></li>
             
             
            
             
              
           
            </ul>
          </div>
        </div>
      </div>
</footer>
  </div>;
};

export default Footer;
