import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import React, { useState,useEffect } from "react";

import Home from "../../pages/home/home";
import Programs from "../../pages/programs/programs";
import About from "../../pages/about-us/about-us";
import Pricing from "../../pages/pricing/pricing";
import FaqPage from "../../pages/faq/faq";
import Blog from "../../pages/blog/blog";
import Locations from "../../pages/find-a-center/find-center";
import Contact from "../../pages/contact-us/contact";
import Trial from "../../pages/free-trial/free-trial";
import { NavLink } from 'react-router-dom';

import "./navbar.css"; // Import custom CSS file

const Navbar = () => {
 
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked); // Toggle the state on button click
  };
 
  return (
    <BrowserRouter>
      
      <div className="nav">
  <input type="checkbox" id="nav-check"/>
  <div className="nav-header">
    <div className="nav-title">
     Happy Coders
    </div>
  </div>
  <div className="nav-btn" id="btn">
    <label for="nav-check">
      <span></span>
      <span></span>
      <span></span>
    </label>
  </div>
  <div className="close-btn">
    <label for="nav-check">
         ✖
    </label>
  
  </div>


  
 
  <div className="nav-links"  >
 
  
          <NavLink  to="/"  >Home</NavLink>
          <NavLink  to="/programs" activeClassName="active" >Programs</NavLink>
          <NavLink to="/about" activeClassName="active">About Us</NavLink>
          <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>      
          <NavLink to="/faq" activeClassName="active">FAQ</NavLink>
          </div>
          
          
 
        
         
     

     
</div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/about" element={<About />} />
    
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/trial" element={<Trial />} />

        <Route path="/contact" element={<Contact />} />
      </Routes>
   
    </BrowserRouter>
    
  );

};

export default Navbar;
