import React from "react";
import "./about-us.css";
import tareqvai from "../../assets/tareqvai.jpg"
const About = () => {
  return (
   <div>


<div class="responsive-container-block bigContainer">
  <div class="responsive-container-block Container">
    
    <img src={require('../../assets/Background.jpg')} width="50%" height="50%" alt="Your Image" className="image" />
    <div class="allText aboveText">
      <p class="text-blk headingText">
        Our Mission
      </p>
      <p class="text-blk subHeadingText">
       Our Mission for 21st Century 
      </p>
      <p class="text-blk description">
 We want to convert the screen time of today’s kids into a happy learning time that they would enjoy as much as they enjoy playing video games or watching YouTube. Kids will build their own games, animations, apps or websites and by doing so they will learn the valuable programming and problem-solving skills and will build the confidence to thrive in the digital world of the 21st century. 
      </p>
      <button class="explore">
        Explore
      </button>
    </div>
  </div>
  <div class="responsive-container-block Container bottomContainer">
<img src={require('../../assets/Background.jpg')} width="50%" height="50%" alt="Your Image" className="image" /> <div class="allText bottomText">
      <p class="text-blk headingText">
        Our Vision
      </p>
      <p class="text-blk subHeadingText">
      Our Vision for 21st Century 
      </p>
      <p class="text-blk description">
      We can’t simply deny technology to kids. Happy Coders will use the same technology to bring positive changes on kids’ development. Learning to code is not just another skill, it helps to improve creativity and problem-solving capability. Happy Coders will teach this invaluable skill in a fun, interactive and engaging way converting kids’ screen time into happy learning time
      </p>
      <button class="explore">
        Explore
      </button>
    </div>
  </div>
</div>

     <div className="headercomponent"  >
        <h1 >Here are our Founders</h1>
      
      </div>
  <div class="row_a">
  <div class="column_a">
  <figure class="snip1390">
  <img src={require('../../assets/tareqvai.jpg')} alt="profile-sample3" class="profile" /> 
  <figcaption>
    <h2>Abu Obaida</h2>
    <h4>Soft Engg</h4>
    <blockquote>He is currently Staff Soft Engg at Walmart Global Tech.Formerly He  worked at  Meta as a Soft Engineer.</blockquote>
  </figcaption>
</figure>
  </div>
  <div class="column_a"><figure class="snip1390">
  <img src={require('../../assets/mazharvai.jpg')} alt="profile-sample3" class="profile" /> 
  <figcaption>
    <h2>Mazhar Islam</h2>
    <h4>Soft Engg</h4>
    <blockquote>He is currently working as Soft Engg at Google,USA.Formerly He  worked at  Intel Corporation.</blockquote>
  </figcaption>
</figure></div>
  <div class="column_a"><figure class="snip1390">
  <img src={require('../../assets/arif_vai.jpg')} alt="profile-sample3" class="profile" /> 
  <figcaption>
    <h2>Ariful Islam</h2>
    <h4>Banker</h4>
    <blockquote>He is currently Head of Credit-small business at BRAC Bank Limited.He also worked at IDLC Finance LTD</blockquote>
  </figcaption>
</figure></div>
 

</div>
<div className="headercomponent"  >
        <h1 >Here are our Instructors</h1>
      
      </div>
      <div class="row_c">
  <div class="column_c">
  <figure class="snip1390">
  <img src={require('../../assets/umo.jpg')} alt="profile-sample3" class="profile" /> 
  <figcaption>
    <h2>U Mo Theing</h2>
    <h4>Instructor</h4>
    <blockquote>
Profession: Web and Mobile App Dev(Freelance)
Education: RUET, ETE</blockquote>
  </figcaption>
</figure>
  </div>
  <div class="column_c"><figure class="snip1390">
  <img src={require('../../assets/mubin.jpg')} alt="profile-sample3" class="profile"  /> 
  <figcaption>
    <h2>Kazi Mubin</h2>
    <h4>Instructor</h4>
    <blockquote>
            Profession: Full Stack Web Developer
            <br/>
            Education: BRAC University, CSE
    </blockquote>
  </figcaption>
</figure></div>
  <div class="column_c"><figure class="snip1390">
  <img src={require('../../assets/rahat.jpg')} alt="profile-sample3" class="profile" />
  <figcaption>
    <h2>Aminul Islam</h2>
    <h4>Instructor</h4>
    <blockquote>
                Profession: Statistical Programmer
                Education: SUST, Mathematics </blockquote>
  </figcaption>
</figure></div>
<div class="column_c"><figure class="snip1390">
  <img src={require('../../assets/sakib.jpg')} alt="profile-sample3" class="profile" />
  <figcaption>
    <h2>Abu Nowshed </h2>
    <h4>Instructor</h4>
    <blockquote>
                Profession: Student
                Education: BUET, Computer Science & Engineering </blockquote>
  </figcaption>
</figure></div>
<div className="row_d">
<div class="column_d"><figure class="snip1390">
  <img src={require('../../assets/atik.jpg')} alt="profile-sample3" class="profile" />
  <figcaption></figcaption>
  <figcaption>
    <h2>Atikur</h2>
    <h4>TA</h4>
    <blockquote> Profession: Reseacrh Assistant<br/>
                Education: Dhaka School of Economics</blockquote>
  </figcaption>
</figure></div>

</div>
</div>

</div>
    



  
  );
};

export default About;
