import React from "react";
import  { useEffect, useState } from "react"
import "./programs.css";
import Background from "../../assets/Background.jpg"
import adult2 from "../../assets/adult2.jpg"
import adult from "../../assets/adult.jpg"
import textstyle from "./programs.css"
import 'bootstrap/dist/css/bootstrap.css';


const Programs = () => {
  const [course, Setcourse] = useState([])

  const fetchcourse = () => {
    fetch("https://api.happycoders.io/Course/")
      .then(response => {
        return response.json()
      })
      .then(data => {
        Setcourse(data)
        
      })
  }
  useEffect(() => {
    fetchcourse()  
  }, [])

  return (
    <div>
      <div className="main_container">
     <section className="hero">
      <div className="hero-background">      
      </div>
      
    </section>

 <div className="header_style">
   <h3 className="subheader-alignment" >
       courses
     </h3>

     <h2 className="header-alignment" >
       Course categories
     </h2>
  </div>
   

   
    <div class="row1">
  <div class="column1" >
  <div class="container1">
  <img src={require('../../assets/image_01.jpeg')} width="100%" height="50%"/>
  
  <div class="overlay">
    <div class="content">
      <h3>Scratch</h3>
      <p>3 levels</p>
      <p>Age(7-10)</p>
    </div>
  </div>
</div>
  </div>
 
  <div class="column1" >
  <div class="container1">
  <img src={require('../../assets/image_02.jpg')} width="100%" height="50%"/>
  
  <div class="overlay">
    <div class="content">
      <h3>Scratch</h3>
      <p>3 levels</p>
      <p>Age(11-14)</p>
    </div>
  
</div>

  </div>
 

  </div>
  <div class="column1" >
  <div class="container1">
  <img src={require('../../assets/image_03.jpeg')} width="100%" height="50%"/>
  
  <div class="overlay">
    <div class="content">
      <h3>Python</h3>
      <p>3 levels</p>
      <p>Adults/kids</p>
    </div>
  </div>
</div>

  </div>
</div>

<div class="row2">
  <div class="column2">
  <div class="card">
  <img src={require('../../assets/python.jpg')} width="100%" height="50%"/>
  <h1>Python</h1>
  <p class="price">Adult</p>
  <p>We Offer Python Courses for both the kids and Adults.</p>
  <p><button>Email US</button></p>
</div>
  </div>
  <div class="column2"> <div class="card">
  <img src={require('../../assets/python.jpg')} width="100%" height="50%"/>
  <h1>Python </h1>
  <p class="price">Kids</p>
  <p>We Offer Python Courses for both the kids and Adults.</p>
  <p><button>Email US</button></p>
</div></div>
  <div class="column2"> 
  <div class="card">
  <img src={require('../../assets/scratch.png')} width="100%" height="50%"/>
  <h1>Scratch</h1>
  <p class="price">Kids</p>
  <p>We Offer Scratch Courses for the kids Only.</p>
  <p><button>Email US</button></p>
</div>
  </div>
</div>
<div className="header_style">
   <h3 className="subheader-alignment" >
       Courses
     </h3>

     <h2 className="header-alignment" >
       Course categories
     </h2>
  </div>
<div className="price">
<div className="spacing">
  <div className="box_container">
<div class="boxes1">
<div class="one_1 box_1">{course.filter(course=>course.position=="01").map(course=>course.header)}
   <p>{course.filter(course=>course.position=="01").map(course=>course.body )}</p>
   
   </div>
   <div class="two_1 box_1">{course.filter(course=>course.position=="02").map(course=>course.header)}
   <p>{course.filter(course=>course.position=="02").map(course=>course.body )}</p>
   
   </div>
   
   <div class="three_1 box_1">{course.filter(course=>course.position=="03").map(course=>course.header)}
   <p>{course.filter(course=>course.position=="03").map(course=>course.body )}</p>
   
   </div>
   </div>
</div>
</div>
</div>
         
        
       
     
     

 
           
     
      
     </div>
    </div>
  );
};

export default Programs;
