import React from "react";
const Trial = () => {
  return (
    <div>
      <h3>Free Trial</h3>
      Call to action button on the right of the menu
      <div>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe2uZ_hfuIV0CXAvFiJUQZwBYTj86ysPNfvWaxC30I3nrCe6g/viewform?embedded=true" width="640" height="956" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
    </div>
  );
};

export default Trial;
