import React from "react";
import './contact.css';
const Contact = () => {
  return (
    <div>
      <section className="hero">
      <div className="hero-background">   
      
     </div>
    </section>
    
    <div class="row_cont">
  <div class="column_cont">
         
  <div class="contact-info">
        <div><i class="fa fa-map-marker"></i> Flat A1 & B1, House 9, Road 1, Block B, Niketon. , Dhaka, Bangladesh</div>
        <div><i class="fa fa-envelope"></i> hellohappycoders@gmail.com</div>
        <div><i class="fa fa-phone"></i> +880-1618-940486</div>
        <div><i class="fa fa-clock-o"></i> Mon - Fri 8:00 AM to 5:00 PM</div>
      </div>
  </div>
  <div class="column_cont">
  <img src={require('../../assets/map_image.png')} width="28%" height="50%"/>

<img src={require('../../assets/map_image.png')} width="20%" height="25%"/>
<img src={require('../../assets/map_image.png')} width="10%" height="12.5%"/>
  </div>
  

  
</div>







    </div>
  );
};
export default Contact;
