import React from "react";
import "./blog.css";
const Blog = () => {
  return (
    <div>
  <div className="row">
  <div className="column">
    <h2>Column 1</h2>
    <p>Some text..</p>
  </div>
  <div className="column">
    <h2>Column 2</h2>
    <p>Some text..</p>
  </div>
  <div className="column" >
    <h2>Column 3</h2>
    <p>Some text..</p>
  </div>
  <div className="column">
    <h2>Column 4</h2>
    <p>Some text..</p>
  </div>
</div>
    </div>
  );
};

export default Blog;
