import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import "./home.css";


const Home = () => {

  const [users, setUsers] = useState([])
  const [why_us, setwhy_us] = useState([])
  const [notice, setnotice] = useState([])


  const fetchData = () => {
    fetch("https://api.happycoders.io/testimonials/")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
        
      })
  }

  const fetchwhy_us= () => {
    fetch("https://api.happycoders.io/Whyus/")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setwhy_us(data)
        
      })
  }
  const fetchnotice= () => {
    fetch("https://api.happycoders.io/Notice/")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setnotice(data)
        
      })
  }

  useEffect(() => {
    fetchData()
    
   
  }, [])
  useEffect(() => {
    fetchwhy_us()
       
  }, [])
  useEffect(() => {
    fetchnotice()
       
  }, [])
  return (
    <div>
    <section className="hero">
      <div className="hero-background">
        <h1 className="hero-title">Coding is the new literacy</h1>

        <h2 className="hero-subtitle">
          We convert kid’s screen time to a happy learning time
        </h2>
            
        <div className="trial-btn ">
          <Link to="/contact">Trial Class</Link>
        </div>
      
    
      </div>
     
    </section>

    <div className="testimonial_head"
      >
          <h1 >Why Us</h1>
        </div>
  
    <div >
      
    <div class="row">
  <div class="column" >
  <figure class="snip1139">
    <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
         
        }}>
          <h6 > {why_us.filter(why_us=>why_us.position=="01").map(why_us=>why_us.header )}</h6>
 <i class="fa fa-home" style={{
         padding: "1rem",
         marginleft:"1rem",
         fontSize:"50px"
         
        }}></i>
  
  </div>
  <blockquote>
  
    
  {why_us.filter(why_us=>why_us.position=="01").map(why_us=>why_us.body )}
    <div class="arrow"></div>
    
  </blockquote>
 
 
</figure>
 
  </div>
  <div class="column" >
  <figure class="snip1139">

  <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
        }}>

 
  <h6>{why_us.filter(why_us=>why_us.position=="02").map(why_us=>why_us.header )}</h6>
  <i class="fa fa-graduation-cap" style={{
         padding: "1rem",
         marginleft:"1rem",
         fontSize:"50px"
        }}></i>
  </div>
    
  <blockquote>{why_us.filter(why_us=>why_us.position=="02").map(why_us=>why_us.body )}
  </blockquote>
 
 
</figure>
  </div>
  <div class="column" >
  <figure class="snip1139">
  <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
        }}>
 
  <h6>{why_us.filter(why_us=>why_us.position=="03").map(why_us=>why_us.header )}</h6>
  <i class="fa fa-globe" style={{
         padding: "1rem",
         marginleft:"1rem",
         fontSize:"50px"
         
        }}></i>
  </div>
  <blockquote>{why_us.filter(why_us=>why_us.position=="03").map(why_us=>why_us.body )}
    <div class="arrow"></div>
    
  </blockquote>
 
 
</figure>
  </div>
  <div class="column" >
  <figure class="snip1139">
  <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
        }}>
 
  <h6>{why_us.filter(why_us=>why_us.position=="04").map(why_us=>why_us.header )}</h6>
  <i class="fa fa-book" style={{
         padding: "1rem",
         marginleft:"1rem",
         fontSize:"50px"
         
        }}></i>
  </div>
  <blockquote>{why_us.filter(why_us=>why_us.position=="01").map(why_us=>why_us.body )}
  </blockquote>
 
 
</figure>
  </div>
</div>

</div>
<div className="notice_head"
      >
          <h1 >Notice Board</h1>
          
        </div>
      
      <div>
     
      <div class="boxes">
   <div class="one box"><p className="paragraph">{notice.filter(notice=>notice.position=="01").map(notice=>notice.body )}</p></div>
   <div class="two box"><p className="paragraph">{notice.filter(notice=>notice.position=="02").map(notice=>notice.body )}</p></div>
   

   
</div>
      </div>


    <div className="testimonial_head"
      >
          <h1 >Testimonials</h1>
        </div>
   
      
    <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
                
        }}>

    </div>
   <div>
   <div class="row">
  <div class="column" >
  <figure class="snip1533">
  <figcaption>
    <blockquote>
       <p>{users.filter((el,i)=>(i===0)).map(el=>(<p>{el.body}</p>))}</p> 
    </blockquote>
   
    <h3>{users.filter((el,i)=>(i===0)).map(el=>(<h3>{el.name}</h3>))}</h3>
    <h3>{users.filter((el,i)=>(i===0)).map(el=>(<h3>{el.location}</h3>))}</h3>
  </figcaption>
</figure>
  </div>
  <div class="column" >
  <figure class="snip1533">
  <figcaption>
    <blockquote>
       <p>{users.filter((el,i)=>(i===1)).map(el=>(<p>{el.body}</p>))}</p> 
    </blockquote>
   
    <h3>{users.filter((el,i)=>(i===1)).map(el=>(<h3>{el.name}</h3>))}</h3>
    <h3>{users.filter((el,i)=>(i===1)).map(el=>(<h3>{el.location}</h3>))}</h3>
  </figcaption>
</figure>
  </div>
  <div class="column">
  <figure class="snip1533">
  <figcaption>
    <blockquote>
       <p>{users.filter((el,i)=>(i===2)).map(el=>(<p>{el.body}</p>))}</p> 
    </blockquote>
   
    <h3>{users.filter((el,i)=>(i===2)).map(el=>(<h3>{el.name}</h3>))}</h3>
    <h3>{users.filter((el,i)=>(i===2)).map(el=>(<h3>{el.location}</h3>))}</h3>
  </figcaption>
</figure>
  </div>
  <div class="column">
  <figure class="snip1533">
  <figcaption>
    <blockquote>
       <p>{users.filter((el,i)=>(i===3)).map(el=>(<p>{el.body}</p>))}</p> 
    </blockquote>
   
    <h3>{users.filter((el,i)=>(i===3)).map(el=>(<h3>{el.name}</h3>))}</h3>
    <h3>{users.filter((el,i)=>(i===3)).map(el=>(<h3>{el.location}</h3>))}</h3>
  </figcaption>
</figure>
  </div>
</div>

   </div>
    
    </div>
    
  );
};

export default Home;
